<template>
  <div class="login-wrapper bg-primary">
    <span class="logo"
      ><img src="../../assets/logo-white.png" alt="" class="vg-logo"
    /></span>
    <div class="login-container">
      <!-- <section class="login-wrap-header">
        <img src="../../assets/logo-white.png" alt="" class="slogan-logo" />
        <span class="system-name"></span>
      </section> -->
      <div class="login-form-container base-shadow">
        <div class="login-banner">
          <div class="draw-content">
            <Lottie :options="options" />
            <!-- <span class="svg-draw"
              ><svg-icon iconClass="undraw_trends"></svg-icon
            ></span> -->
          </div>
          <!-- <img src="../../assets/login-banner.jpg" alt="" class="login-banneer" /> -->
        </div>
        <div class="login-form">
          <p class="banner-title weight text-primary">数据可视化</p>
          <p class="banner-title light">让招商更容易</p>
          <br />
          <br />
          <br />
          <!-- <div class="toggle-btn-group" >
            <div
              class="toggle-btn"
              :class="[loginType == 'phone' ? 'active base-shadow' : '']"
              @click="toggleLoginType('phone')"
            >
              手机号登录
            </div>
            <div
              class="toggle-btn"
              :class="[loginType == 'account' ? 'active base-shadow' : '']"
              @click="toggleLoginType('account')"
            >
              账号密码登录
            </div>
          </div> -->
          <AccountLoginForm
            :redirect="redirect"
            v-show="loginType == 'account'"
          />
          <!-- <SmsLoginForm :redirect="redirect" v-show="loginType == 'phone'" /> -->
        </div>
      </div>
    </div>

    <div class="login-footer">
      <span class="copyright-text"
        >Copyright &copy; 2020-2022 佛山市塞外科技有限公司 版权所有
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >粤ICP备2021028384号-1</a
        ></span
      >
    </div>
  </div>
</template>

<script>
import AccountLoginForm from "./components/AccountLoginForm";
import SmsLoginForm from "./components/SmsLoginForm";
import Lottie from "@/components/Lottie.vue";
import animationData from "@/assets/lotties/software.json";
export default {
  components: {
    AccountLoginForm,
    SmsLoginForm,
    Lottie,
  },
  data() {
    return {
      loading: false,
      redirect: "",
      loginType: "account",
      options: {
        animationData: animationData,
      },
    };
  },
  created() {
    if (this.$route.query.message) {
      this.$message.error(this.$route.query.message);
    }
    if (this.$route.query.redirect) {
      this.redirect = this.$route.query.redirect;
    }
  },
  methods: {
    toggleLoginType(type) {
      this.loginType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
$base-width: 960px;
$base-color: #03a1ea;
.login-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  // &::before {
  //   content: "";
  //   width: 120%;
  //   height: 120%;
  //   position: absolute;
  //   top: -10px;
  //   left: -10px;
  //   background-color: rgba(0, 0, 0, 0.3);
  //   z-index: 1;
  // }
  // &::after {
  //   content: "";
  //   width: 120%;
  //   height: 120%;
  //   position: absolute;
  //   top: -10px;
  //   left: -10px;
  //   filter: blur(10px);
  //   background-color: #fff;
  //   background-image: url("../../assets/login-bg.jpg");
  //   background-size: 100% auto;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   z-index: 0;
  // }
  .logo {
    z-index: 3;
    position: absolute;
    top: 10px;
    left: 30px;
    display: inline-block;
    height: 40px;
    text-align: left;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .login-container {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: #fff;
    z-index: 2;
  }
  .login-wrap-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 50px;
    width: $base-width;
    margin: 0 auto;

    .slogan-logo {
      max-height: 50px;
    }
    .system-name {
      display: inline-block;
      padding-left: 20px;
      margin-left: 20px;
      font-size: 32px;
      font-weight: 400;
      color: #333;
      position: relative;
      // &::before {
      //   content: "";
      //   width: 2px;
      //   height: 32px;
      //   background-color: #333;
      //   position: absolute;
      //   top: 50%;
      //   left: 0;
      //   transform: translateY(-50%);
      // }
    }
  }
  .login-form-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80%;
    margin: 0 auto;
    color: #333;
    border-radius: 10px;
    .banner-title {
      font-size: 26px;
      text-align: left;
      &.weight {
        font-weight: 800;
      }
      &.light {
        font-size: 20px;
        font-weight: 300;
      }
    }
  }
  .login-footer {
    z-index: 3;
    width: 100%;
    height: 60px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    .copyright-text {
      box-sizing: border-box;
      padding: 4px 10px;
      border-radius: 20px;
      color: #fff;
      // background: #ddd; /* fallback for old browsers */

      overflow: hidden;
      & a {
        color: rgb(189, 185, 185);
        &:hover {
          color: #03a1ea;
        }
      }
    }
  }
  .login-banner {
    flex: 50%;
    box-sizing: border-box;
    padding: 30px;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    .draw-content {
      width: 100%;
      // height: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .svg-draw {
        font-size: 370px;
      }
    }
  }
  .login-form {
    box-sizing: border-box;
    flex: 50%;
    width: 400px;
    height: auto;
    margin-right: 120px;
    padding: 50px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    .other-login-item {
      box-sizing: border-box;
      padding: 10px 0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      color: #000;
      .other-login-title {
        padding-right: 20px;
      }
      .other-login-icon {
        font-size: 24px;
        transition: all 0.3s linear;
        cursor: pointer;
        color: #989898;
        &.wechat {
          &:hover {
            color: #52c41a;
          }
        }
      }
    }
  }
  .toggle-btn-group {
    box-sizing: border-box;
    padding: 0 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    .toggle-btn {
      flex: 1;
      box-sizing: border-box;
      padding: 10px 10px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      &:hover {
        color: $theme_color;
      }
      &.active {
        transition: all 0.3s linear;
        background-color: $theme_color;
        color: #fff;
        transform: translateY(-1px);
      }
    }
  }
}
</style>
